
@import './flex-layout-breakpoint-css-generator.scss';

// ========== xxx.xs ==========
@media screen and (max-width: 599.98px){

    // fxFlex.xs="XX" 
    $flexWidths: 40, 50, 60, 70, 100;

    // fxLayoutGap.xs="XX"
    $flexLayoutGaps: 0.75rem, 1rem, 5px, 10px;

    // fxLayout.xs="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.xs="XX"
    $flexLayoutAlignTypes: start start, start, space-between center, center center, start stretch,
    start space-between, space-betweeen ;

    @include generate-breakpoint-css(xs, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);  
}

// ========== xxx.gt-xs ==========
@media screen and (min-width: 600px) {

    // fxFlex.gt-xs="XX" 
    $flexWidths: 100;

    // fxLayoutGap.gt-xs="XX"
    $flexLayoutGaps: 1rem;

    // fxLayout.gt-xs="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.gt-xs="XX"
    $flexLayoutAlignTypes: space-around top, space-between center;

    @include generate-breakpoint-css(gt-xs, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.sm ==========
@media screen and (min-width: 600px) and (max-width: 959.98px){

    // fxFlex.sm="XX" 
    $flexWidths: 33, 50, 60, 100;

    // fxLayoutGap.sm="XX"
    $flexLayoutGaps: 0.75rem, 1rem;

    // fxLayout.sm="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.sm="XX"
    $flexLayoutAlignTypes: space-between, space-between center, center center, flex-start center;

    @include generate-breakpoint-css(sm, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.lt-sm ==========
@media screen and (max-width:599.98px){

    // fxFlex.lt-sm="XX" 
    $flexWidths: 35, 36, 40, 60, 65, 80, 100;

    // fxLayoutGap.lt-sm="XX"
    $flexLayoutGaps: 1rem, 5px;

    // fxLayout.lt-sm="XX"
    $flexLayoutTypes: row, column, column-reverse;

    // fxLayoutAlign.lt-sm="XX"
    $flexLayoutAlignTypes: space-between center, start;

    @include generate-breakpoint-css(lt-sm, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.gt-sm ==========
@media screen and (min-width: 960px) {

    // fxFlex.gt-sm="XX" 
    $flexWidths: 100;

    // fxLayoutGap.gt-sm="XX"
    $flexLayoutGaps: 1rem, unset;

    // fxLayout.gt-sm="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.gt-sm="XX"
    $flexLayoutAlignTypes: space-between center;

    @include generate-breakpoint-css(gt-sm, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.md ==========
@media screen and (min-width: 960px) and (max-width: 1279.98px){

    // fxFlex.md="XX" 
    $flexWidths: 35, 40, 50, 55, 60, 65, 90, 100;

    // fxLayoutGap.md="XX"
    $flexLayoutGaps: 1rem, 1.6em;

    // fxLayout.md="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.md="XX"
    $flexLayoutAlignTypes: flex-start center, center center, space-between center;

    @include generate-breakpoint-css(md, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.lt-md ==========
@media screen and (max-width:959.98px){

    // fxFlex.lt-md="XX" 
    $flexWidths: 25,30,40,70,100, auto;

    // fxLayoutGap.lt-md="XX"
    $flexLayoutGaps:  0.25rem, 0.5rem, 1rem, 3.188rem, 7px, 10px;

    // fxLayout.lt-md="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.lt-md="XX"
    $flexLayoutAlignTypes: start start, space-between center;

    @include generate-breakpoint-css(lt-md, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.gt-md ==========
@media screen and (min-width: 1280px) {

    // fxFlex.gt-md="XX" 
    $flexWidths: 100;

    // fxLayoutGap.gt-md="XX"
    $flexLayoutGaps: 1rem;

    // fxLayout.gt-md="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.gt-md="XX"
    $flexLayoutAlignTypes: space-between center;

    @include generate-breakpoint-css(gt-md, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.lg ==========
@media screen and (min-width: 1280px) and (max-width: 1919.98px){

    // fxFlex.lg="XX" 
    $flexWidths: 100;

    // fxLayoutGap.lg="XX"
    $flexLayoutGaps: 1rem;

    // fxLayout.lg="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.lg="XX"
    $flexLayoutAlignTypes: space-between center;

    @include generate-breakpoint-css(lg, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.lt-lg ==========
@media screen and (max-width:1279.98px){

    // fxFlex.lt-lg="XX" 
    $flexWidths: 100;

    // fxLayoutGap.lt-lg="XX"
    $flexLayoutGaps: 1rem, 32px;

    // fxLayout.lt-lg="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.lt-lg="XX"
    $flexLayoutAlignTypes: space-between center;

    @include generate-breakpoint-css(lt-lg, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.gt-lg ==========
@media screen and (min-width: 1920px) {

    // fxFlex.gt-lg="XX" 
    $flexWidths: 100;

    // fxLayoutGap.gt-lg="XX"
    $flexLayoutGaps: 1rem;

    // fxLayout.gt-lg="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.gt-lg="XX"
    $flexLayoutAlignTypes: space-between center;

    @include generate-breakpoint-css(gt-lg, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.xl ==========
@media screen and (min-width: 1920px) {

    // fxFlex.xl="XX" 
    $flexWidths: 100;

    // fxLayoutGap.xl="XX"
    $flexLayoutGaps: 1rem;

    // fxLayout.xl="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.xl="XX"
    $flexLayoutAlignTypes: space-between center;

    @include generate-breakpoint-css(xl, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}

// ========== xxx.lt-xl ==========
@media screen and (max-width:1919.98px){

    // fxFlex.lt-xl="XX" 
    $flexWidths: 100;

    // fxLayoutGap.lt-xl="XX"
    $flexLayoutGaps: 1rem;

    // fxLayout.lt-xl="XX"
    $flexLayoutTypes: row, column;

    // fxLayoutAlign.lt-xl="XX"
    $flexLayoutAlignTypes: space-between center;

    @include generate-breakpoint-css(lt-xl, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes);
}
