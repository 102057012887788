/* -------------------------Styles for Dialogs Start-------------------------*/
.mat-mdc-dialog-container {
  padding: 0px !important;
  z-index: 9999;
}

::ng-deep .mat-mdc-dialog-container {
  z-index: 9999;
}

.header {
  background: #444751;
  color: #fff;
  margin: 0;
  font-size: 16px ;
  position: relative;
  padding:  19px 50px 18px 20px;  /*19px 50px 18px 5px;*/
}

.webpage-header {
  padding: 10px 50px 5px 20px;
}

.loader-message {
  min-height: 100px;
  text-align: center;
}

.content {
  padding: 10px;
  max-height: 500px;
  overflow: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container .content {
  min-height: 250px;
}

@media only screen and (min-width: 660px) and (max-width: 812px) {
  .content {
    max-height: 230px !important;
    overflow: auto !important;
  }
}

.footer {
  text-align: right;
  margin: 0;
  padding: 10px 15px 10px;
  background: #f5f5f5;
  width: 100%;
}

.dialog-close-button {
	position: absolute;
	right: 20px;
	top: 20px;
	color: #d1d1d1 !important;
	cursor: pointer;
}

.element-gap {
  padding: 10px;
}


@media only screen and (max-width: 767px) {
  .header {
    padding: 15px 50px 15px 15px;
    font-size: 1.15rem;
  }

  .webpage-header {
    padding: 5px 0px 0px 10px;
  }

  .content {
    padding: 5px;
  }

  .footer {
    padding: 5px 5px 5px;
  }

  .footer button {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .dialog-close-button {
    right: 15px;
    top: 15px;
  }

  .element-gap {
    padding: 5px;
  }
}

cdk-global-overlay-wrapper {
  overflow: auto;
  pointer-events: auto;
}

uxlib-dialog {
  .mat-mdc-dialog-content {
    overflow: auto !important;
  }
 .mat-mdc-dialog-container {
   overflow: hidden !important;
  .mat-mdc-dialog-content {
    overflow: auto !important;
  }
 }
}

/* ------------------------Styles for Dialogs end-----------------*/

.mat-mdc-dialog-container{
  // max-height: 370px !important;
  // height: 370px !important;
  width: 870px !important;
  padding: 0px !important;
  z-index: 1 !important;
  overflow: hidden  !important;
}

.mat-mdc-dialog-title{
  background: var(--secondary-color) ;
  // color: ;
  color: #fff;
  position: relative;
  padding: 19px 50px 3px 20px;
  text-transform: uppercase;
}

.mat-mdc-dialog-title button {
  float: right;
  top: -54px;
  right: -24px;
  outline: 0 !important;
}

.mat-mdc-dialog-content {
  overflow: hidden !important;
  width: 100% !important;
  // max-height: 30vh!important;
  height: 310px!important;
  margin: 0!important;
}

.mat-mdc-dialog-actions.mat-dialog-actions{
  padding: 0px 13px!important;
  background: #f5f5f5;
  margin-bottom: 6px !important;
}

uxlib-dialog .mat-mdc-dialog-actions.mat-dialog-actions{
  background-color: #fff !important;
  margin: -22px 1px;
}

uxlib-dialog .mat-mdc-dialog-title button {
  top: 0 !important;
  right: 0 !important;
}

@media (max-width: 1600px) {
  uxlib-dialog .mat-mdc-dialog-content {
    max-height: unset !important;
  }
  uxlib-dialog .mat-mdc-dialog-actions {
    padding: 0.7em !important;
    max-height: 4.5em !important;
  }
}

uxlib-dialog .mat-mdc-dialog-content {
  height: 100% !important;
}

/* dialog css start */
.mat-mdc-dialog-container {
  width: 58.1875rem !important;

}

.mat-mdc-dialog-title::before {
  display: none !important;
}

.mat-mdc-dialog-title {
  display: flex !important;
  padding: 1.5rem 1.5rem 1.5rem 2rem !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: var(--primary-color) !important;
}

.mat-mdc-dialog-title div>h2 {
  padding-left: 3.313rem;
}

.mat-mdc-dialog-title div>img {
  position: absolute;
  height: 3rem;
}

.mat-mdc-dialog-title>h2,
.mat-mdc-dialog-title div>h2 {
  margin-bottom: 0rem !important;
  color: var(--tertiary-color) !important;
  text-transform: capitalize !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  line-height: 1.875rem !important;
}

.mat-mdc-dialog-title>button {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: var(--primary-color);
  border: none;
  padding: 0;
  outline: none;
}

.mat-mdc-dialog-title>button>.mat-icon {
  font-weight: 700;
}

.mat-mdc-dialog-title>.mat-mdc-mini-fab {
  box-shadow: unset !important;
}


.mat-mdc-dialog-title>.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: var(--tertiary-color) !important;
  --mdc-fab-icon-color: var(--primary-color) !important;
  --mat-mdc-fab-color: var(--primary-color) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  height: auto !important;
  border-radius: 1rem !important;
}

.mat-mdc-dialog-actions {
  padding: 2rem !important;
  justify-content: center !important;
  gap: 1.375rem !important;
}

.mat-mdc-dialog-actions>.uxlib-btn-save,
.mat-mdc-dialog-actions>.uxlib-btn-close.primary-bg {
  background: var(--primary-color) !important;
}

.mat-mdc-dialog-actions>.uxlib-btn-close {
  background: #E6E6E6 !important;
}

.mat-mdc-dialog-actions>.uxlib-btn-save,
.mat-mdc-dialog-actions>.uxlib-btn-close {
  min-width: 12.8125rem !important;
  display: flex !important;
  padding: 0.75rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem !important;
  align-self: stretch !important;
  border-radius: 2rem !important;
  height: 3rem !important;
}

.mat-mdc-dialog-actions>.disabled {
  background: #A1A1A1 !important;
}

.mat-mdc-dialog-actions>.uxlib-btn-save>*,
.mat-mdc-dialog-actions>.uxlib-btn-close.primary-bg>* {
  color: #F0F0F0 !important;
}

.mat-mdc-dialog-actions>.uxlib-btn-close>* {
  color: var(--quinary-color) !important;
}

.mat-mdc-dialog-actions>.uxlib-btn-save>*,
.mat-mdc-dialog-actions>.uxlib-btn-close>* {
  font-family: "Open Sans" !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.5rem !important;
  text-transform: capitalize !important;
}

.mat-mdc-dialog-content .custom-mat-card-forms {
  box-shadow: unset !important;
}
/* dialog css end */